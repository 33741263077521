import _ from 'lodash'
import {
  getPerson,
  removePersonObjectProperty,
  setPersonObjectProperty,
} from '../../../slices/questionnaireSlice/utils/helpers'

const removePersonAttribute = (person, type) => {
  delete person[type]
}

const setPersonName = (state, action, type, isPrevious = false) => {
  const { value } = action.payload
  const person = getPerson(state, action)
  const name = isPrevious ? 'previousName' : 'name'

  if (!value) {
    if (!person[name]) return
    // remove specific name
    if (person[name][type]) delete person[name][type]

    // if all names are empty remove the object
    const nameValues = Object.values(person[name])
    if (nameValues.length && nameValues.every((value) => !value)) {
      removePersonAttribute(person, isPrevious ? 'previousName' : 'name')
    }

    return
  }

  // add name object if it doesn't exist
  if (!person[name]) {
    person[name] = {}
  }
  person[name][type] = value
}

export default {
  /**
   * Delete simple person property 1st level entirely (ie. sex, dateOfBirth)
   * @type {(state: WritableDraft<{}>, action: { payload: { id: string, type: string }})}
   */
  removePersonProperty(state, action) {
    const person = getPerson(state, action)

    removePersonAttribute(person, action.payload.type)
  },
  /**
   * Set person.name.firstName: string
   * @type {PersonReducer}
   */
  setPersonFirstName(state, action) {
    setPersonName(state, action, 'firstName')
  },
  /**
   * Set person.name.lastName: string
   * @type {PersonReducer}
   */
  setPersonLastName(state, action) {
    setPersonName(state, action, 'lastName')
  },
  /**
   * Set person.name.lastNameAtBirth: string
   * @type {PersonReducer}
   */
  setPersonLastNameAtBirth(state, action) {
    setPersonName(state, action, 'lastNameAtBirth')
  },
  /**
   * Set person.previousName.firstName: string
   * @type {PersonReducer}
   */
  setPersonPreviousFirstName(state, action) {
    setPersonName(state, action, 'firstName', true)
  },
  /**
   * Set person.previousName.lastName: string
   * @type {PersonReducer}
   */
  setPersonPreviousLastName(state, action) {
    setPersonName(state, action, 'lastName', true)
  },
  /**
   * delete person.name: string
   * @type {PersonReducer}
   */
  removePersonNameProperty(state, action) {
    const { type } = action.payload
    const person = getPerson(state, action)

    if (!person.name) {
      throw new Error('Unable to find the person name.')
    }

    if (person.name[type]) {
      delete person.name[type]

      if (Object.values(person.name).every((value) => !value) || !Object.keys(person.name).length) {
        removePersonAttribute(person, 'name')
      }
    }
  },
  /**
   * Set person.dateOfBirth properties: number
   * @type {PersonReducer}
   */
  setPersonDateOfBirth(state, action) {
    const { value, dateType } = action.payload
    const person = getPerson(state, action)
    const dateOfBirth = person.dateOfBirth ?? {}

    person.dateOfBirth = {
      ...dateOfBirth,
      [dateType]: value,
    }
  },
  /**
   * Set person.sex: string
   * @type {PersonReducer}
   */
  setPersonSex: (state, action) => {
    const person = getPerson(state, action)
    person.sex = action.payload.value
  },
  /**
   * Set person.genderIdentity: string
   * @type {PersonReducer}
   */
  setPersonGenderIdentity: (state, action) => {
    const person = getPerson(state, action)
    person.genderIdentity = action.payload.value
  },
  /**
   * Remove person.genderIdentity
   * @type {PersonReducer}
   */
  removeGenderIdentity: (state, action) => {
    const person = getPerson(state, action)

    removePersonAttribute(person, 'genderIdentity')
  },
  /**
   * Set a property in person.adopted: string
   * @type {PersonReducer}
   */
  setPersonAdopted: (state, action) => {
    const person = getPerson(state, action)
    person.adopted = action.payload.value
  },
  /**
   * Set a property in person.lifeStatus: { alive: 'Y' }
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string, value: string }}
   * )}
   */
  setPersonLifeStatus(state, action) {
    setPersonObjectProperty(state, action, 'lifeStatus')
  },
  /**
   * Remove property in person.lifeStatus: { performed: 'Y' }
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string }}
   * )}
   */
  removePersonLifeStatus(state, action) {
    removePersonObjectProperty(state, action, 'lifeStatus')
  },
  /**
   * Resets all properties related to a person's gender
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string }}
   * )}
   */
  removePersonSexSpecificQuestions(state, action) {
    const { id } = action.payload
    const proband = getPerson(state, action)

    const {
      cancerRiskData = {},
      genderIdentity = '',
      name = {},
      conditions = [],
      surgeries = [],
      diagnosticTests = [],
    } = proband

    const _proband = _.cloneDeep(proband)

    if (genderIdentity) {
      delete _proband.genderIdentity
    }

    if (name?.lastNameAtBirth) {
      delete _proband.name.lastNameAtBirth

      if (Object.values(_proband.name).every((value) => !value)) {
        delete _proband.name
      }
    }

    const conditionIndex = conditions.findIndex(({ label }) => label === 'Endometriosis')
    if (conditionIndex > -1) {
      const deletedConditions = [..._proband.conditions]
      deletedConditions.splice(conditionIndex, 1)

      _proband.conditions = deletedConditions
    }

    if (surgeries?.length > 0) {
      _proband.surgeries = []
    }

    if (diagnosticTests?.length > 0) {
      _proband.diagnosticTests = []
    }

    if (!cancerRiskData) return

    if (cancerRiskData.ageAtMenarche) {
      delete _proband.cancerRiskData.ageAtMenarche
    }

    if (cancerRiskData.oralContraceptiveUsage) {
      delete _proband.cancerRiskData.oralContraceptiveUsage
    }
    if (cancerRiskData.extendedCancerRiskDataList) {
      delete _proband.cancerRiskData.extendedCancerRiskDataList
    }

    if (cancerRiskData.menopauseStatus) {
      delete _proband.cancerRiskData.menopauseStatus
    }

    if (cancerRiskData.ageAtMenopause) {
      delete _proband.cancerRiskData.ageAtMenopause
    }

    if (cancerRiskData.hrtUsage) {
      delete _proband.cancerRiskData.hrtUsage
    }

    if (cancerRiskData.hrtLengthOfUse) {
      delete _proband.cancerRiskData.hrtLengthOfUse
    }

    if (cancerRiskData.hrtType) {
      delete _proband.cancerRiskData.hrtType
    }

    if (cancerRiskData.previousBreastBiopsies) {
      delete _proband.cancerRiskData.previousBreastBiopsies
    }

    if (cancerRiskData.breastBiopsyResult) {
      delete _proband.cancerRiskData.breastBiopsyResult
    }

    state.persons[id] = _proband
  },
}
