import { Box, useMediaQuery } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { getLabel, renderContent, charRestriction, pathToPerson } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'
import FormLabel from './FormLabel'
import TextFieldQuestion from './TextFieldQuestion.jsx'

const { FIRST_NAME, LAST_NAME } = PersonFieldPaths

export const Name = ({ type, id, isProband, localization }) => {
  const {
    setPersonFirstName,
    setPersonLastName,
    setPersonPreviousFirstName,
    setPersonPreviousLastName,
  } = useActions()
  const action = {
    previousNameFirst: setPersonPreviousFirstName,
    previousNameLast: setPersonPreviousLastName,
    nameFirst: setPersonFirstName,
    nameLast: setPersonLastName,
  }
  const labelField = {
    previousNameFirst: FIRST_NAME,
    previousNameLast: LAST_NAME,
    nameFirst: FIRST_NAME,
    nameLast: LAST_NAME,
  }
  const isPrevious = type === 'previousNameFirst' || type === 'previousNameLast'
  const value =
    useSelector((state) => {
      const nameType = {
        previousNameFirst: 'firstName',
        previousNameLast: 'lastName',
        nameFirst: 'firstName',
        nameLast: 'lastName',
      }[type]

      return isPrevious
        ? get(state, [...pathToPerson(id), 'previousName', nameType])
        : get(state, [...pathToPerson(id), 'name', nameType])
    }) || ''

  if (!action || !labelField) return

  return (
    <FormLabel
      label={
        (isPrevious ? 'Previous ' : '') +
        getLabel(localization, labelField[type]) +
        (isProband ? ' *' : '')
      }
    >
      <TextFieldQuestion
        onChangeCapture={(e) => {
          e.target.value = charRestriction(e.target.value)
        }}
        handleChange={(_value) => action[type]({ id, value: _value })}
        value={value}
        multiline={false}
      />
    </FormLabel>
  )
}

export const PreviousName = ({ id, localization }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  if (!id) return

  return (
    <Box display="flex" gap={3} flexWrap={isMobile && 'wrap'}>
      <Name type={'previousNameFirst'} {...{ id, localization }} />
      <Name type={'previousNameLast'} {...{ id, localization }} />
    </Box>
  )
}

function FullName({ id, isProband, localization, config }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const nameProps = { id, isProband, localization }

  if (!id) return

  return (
    <>
      <Box display="flex" gap={3} flexWrap={isMobile && 'wrap'}>
        {renderContent({
          renderMap: { nameFirst: <Name key="fn" type={'nameFirst'} {...nameProps} /> },
          config,
        })}
        {renderContent({
          renderMap: { nameLast: <Name key="ln" type={'nameLast'} {...nameProps} /> },
          config,
        })}
      </Box>
    </>
  )
}

export default FullName
