import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson, renderContent } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import FormLabel from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'

const ContraceptivePills = ({ id, patient = 'patient', config }) => {
  const localization = useLocalization()
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex'])) || ''
  const oralContraceptiveUsage =
    useSelector((state) =>
      get(state, [...pathToPerson(id), 'cancerRiskData', 'oralContraceptiveUsage']),
    ) || undefined
  const ocpYears =
    useSelector((state) => {
      const extendedCancerRiskDataList =
        get(state, [...pathToPerson(id), 'cancerRiskData', 'extendedCancerRiskDataList']) || []

      return extendedCancerRiskDataList.find(
        ({ extendedCancerRiskDataType }) => extendedCancerRiskDataType === 'ocpYears',
      )
    }) || {}
  const {
    setPersonOralContraceptiveUsage,
    setPersonExtendedCancerRisk,
    removePersonExtendedCancerRisk,
  } = useActions()

  if (sex !== 'F') return null

  return (
    <>
      <Chooser
        label={getLabel(localization, paths.ORAL_CONTRACEPTIVE_PILLS_USAGE, patient)}
        onChange={(value) => {
          if (value !== oralContraceptiveUsage) {
            setPersonOralContraceptiveUsage({ id, value })

            if (value === 'never' || value === 'unknown') {
              removePersonExtendedCancerRisk({ id, type: 'ocpYears' })
            }
          }
        }}
        selectedValue={oralContraceptiveUsage}
        patient={patient}
        path={paths.ORAL_CONTRACEPTIVE_PILLS_USAGE}
        orderSchema={['never', 'unknown', 'stopped2', 'usedWithin2']}
      />
      {oralContraceptiveUsage === 'usedWithin2' || oralContraceptiveUsage === 'stopped2' ? (
        <FollowUpQuestions>
          {renderContent({
            renderMap: {
              ocpYears: (
                <FormLabel key="br" label="For how many years total?">
                  <IntegerDetails
                    value={ocpYears.value || ''}
                    onChange={(value) => {
                      setPersonExtendedCancerRisk({ id, type: 'ocpYears', value })
                    }}
                  />
                </FormLabel>
              ),
            },
            config,
          })}
        </FollowUpQuestions>
      ) : null}
    </>
  )
}

export default ContraceptivePills
