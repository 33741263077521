import { MenuItem, Select } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson, renderContent } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import ConditionalSection from '../../widgets/ConditionalSection'
import FormLabel from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'

const drinkTypeList = [
  { label: 'Glass of Wine (175 ml / 11% ABV)', id: 'wine' },
  { label: 'Pint of Beer (568 ml / 4% ABV)', id: 'beerPint' },
  { label: 'Bottle of Beer (330 ml / 5% ABV)', id: 'beerBottle' },
  { label: 'Alcoholic Pop Drink (275 ml / 4% ABV)', id: 'alcoholicPop' },
  { label: 'Shot of Spirits (25 ml / 40% ABV)', id: 'spiritShot' },
]

export const AlcoholType = ({ id, typeOfDrink }) => {
  const { setPersonTypeOfDrink } = useActions()

  return (
    <FormLabel label="Which of the following drinks do you most frequently consume?">
      <Select
        value={typeOfDrink}
        onChange={({ target: { value } }) => {
          if (value !== typeOfDrink) {
            setPersonTypeOfDrink({ id, value })
          }
        }}
      >
        {drinkTypeList.map(({ id: drinkId, label }) => {
          return (
            <MenuItem key={drinkId} value={drinkId}>
              {label ?? drinkId}
            </MenuItem>
          )
        })}
      </Select>
    </FormLabel>
  )
}

export const AlcoholDrinks = ({ id }) => {
  const { setPersonDrinkAmount, setPersonDrinkingFrequency } = useActions()
  const drinkAmount =
    useSelector((state) =>
      get(state, [
        ...pathToPerson(id),
        'cancerRiskData',
        'alcoholConsumptionDetails',
        0,
        'drinkAmount',
      ]),
    ) || ''
  const drinkingFrequency =
    useSelector((state) =>
      get(state, [
        ...pathToPerson(id),
        'cancerRiskData',
        'alcoholConsumptionDetails',
        0,
        'drinkingFrequency',
      ]),
    ) || ''

  return (
    <FormLabel label={'On average, how many drinks do you have each week?'}>
      <IntegerDetails
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            max: 150,
          },
        }}
        value={drinkAmount}
        onChange={(value) => {
          setPersonDrinkAmount({ id, value })
          if (!drinkingFrequency && value) {
            setPersonDrinkingFrequency({ id, value: 'weekly' })
          }
        }}
      />
    </FormLabel>
  )
}

const Alcohol = ({ id, patient, config }) => {
  const localization = useLocalization()
  const { setPersonConsumesAlcohol, removePersonCancerRiskProperty } = useActions()
  const consumesAlcohol = useSelector((state) =>
    get(state, [...pathToPerson(id), 'cancerRiskData', 'consumesAlcohol']),
  )
  const typeOfDrink =
    useSelector((state) =>
      get(state, [
        ...pathToPerson(id),
        'cancerRiskData',
        'alcoholConsumptionDetails',
        0,
        'typeOfDrink',
      ]),
    ) || ''

  let conditionState
  switch (consumesAlcohol) {
    case true:
      conditionState = 'Y'
      break
    case false:
      conditionState = 'N'
      break
    default:
      conditionState = null
  }

  return (
    <ConditionalSection
      label={getLabel(localization, paths.ALCOHOL_USAGE_LABEL, patient)}
      conditionState={conditionState}
      onConditionStateChange={(value) => {
        if (value !== consumesAlcohol) {
          setPersonConsumesAlcohol({ id, value: value === 'Y' })
        }
      }}
      cleanUpChildQuestions={async () => {
        removePersonCancerRiskProperty({ id, type: 'alcoholConsumptionDetails' })
      }}
      showWhenCondition="Y"
      orderSchema={['Y', 'N']}
    >
      {renderContent({
        renderMap: {
          alcoholType: <AlcoholType key="at" id={id} typeOfDrink={typeOfDrink} />,
          alcoholAmount: (
            <AlcoholDrinks
              key="am"
              id={id}
              disabled={!typeOfDrink}
              disabledMinus={!typeOfDrink}
              disabledPlus={!typeOfDrink}
            />
          ),
        },
        config,
      })}
    </ConditionalSection>
  )
}

export default Alcohol
