import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DownloadIcon from '@mui/icons-material/Download'
import LogoutIcon from '@mui/icons-material/Logout'
import ReplayIcon from '@mui/icons-material/Replay'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, useMediaQuery } from '@mui/material'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Auth } from '../api/Auth'
import { Download } from '../api/Download'
import { useActions } from '../hooks/useActions'
import { theme } from '../theme'
import Context from '../utils/context/Context'
import { SessionContext } from './SessionTimeout'

function Controls({
  demoMode,
  handleMovePrevious,
  canMovePrevious,
  isLastStep,
  saveAndMoveToNext,
  setSubmitWarning,
  btnLabels,
  loadingControls,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const history = useHistory()
  const { loading, addToast } = useContext(Context)
  const { activate } = useContext(SessionContext)
  const { resetState, sendStateData } = useActions()

  const saveThen = async () => {
    await sendStateData()
    addToast('save', 'success', 'Save completed')
    activate()

    return true
  }

  return (
    <Box
      sx={{
        backdropFilter: 'blur(10px)',
        bgColor: 'white.transparent',
        boxShadow: 2,
        bottom: '0',
        display: 'flex',
        gap: 2,
        position: 'sticky',
        py: 3,
        px: 10,
        zIndex: '1',
        [theme.breakpoints.down('md')]: {
          position: 'fixed',
          py: 2,
          px: 2,
          width: '100%',
        },
      }}
    >
      {/* Save button */}
      {isMobile ? (
        <IconButton
          onClick={async () => {
            loadingControls('save', true)
            await saveThen()
            loadingControls('save', false)
          }}
          disabled={!!loading?.save}
          sx={{ width: 45, height: 45 }}
        >
          <SaveIcon />
        </IconButton>
      ) : (
        <LoadingButton
          startIcon={<SaveIcon />}
          onClick={async () => {
            loadingControls('save', true)
            await saveThen()
            loadingControls('save', false)
          }}
          loading={!!loading?.save}
          variant="outlined"
        >
          <span>Save</span>
        </LoadingButton>
      )}
      {/* Dev/Other Buttons */}
      {demoMode && !isMobile && (
        <>
          <LoadingButton
            color="error"
            startIcon={<ReplayIcon />}
            disabled={!!loading?.reset}
            loading={!!loading?.save}
            variant="outlined"
            onClick={async () => {
              loadingControls('reset', true)

              await resetState()

              addToast(
                'reset',
                'success',
                'Form Responses Reset. You must manually remove patients from the ' +
                  'PhenoTips dashboard.',
              )
              loadingControls('reset', false)
            }}
          >
            Reset
          </LoadingButton>
          {/* Why map uses only two buttons? */}
          {demoButtons.map((buttonInfo) => (
            <Button
              key={buttonInfo.id}
              color={buttonInfo.intent}
              startIcon={buttonInfo.icon}
              onClick={async () => {
                loadingControls(buttonInfo.id, true)

                const done = await saveThen()

                if (!done) {
                  loadingControls(buttonInfo.id, false)

                  return
                }

                if (buttonInfo.id === 'logout') {
                  await Auth.logout({ addToast })

                  history.push('/user-logout', {
                    message:
                      'You have logged out. Please login again to restart the questionnaire.',
                  })
                } else {
                  await Download.pdfDownload({ addToast })
                  loadingControls(buttonInfo.id, false)
                }
              }}
              disabled={loading ? !!loading[buttonInfo.id] : false}
            >
              {buttonInfo.text}
            </Button>
          ))}
        </>
      )}
      <Box flexGrow={1}></Box>
      <LoadingButton
        variant="outlined"
        onClick={async () => {
          loadingControls('save', true)
          await handleMovePrevious()
          loadingControls('save', false)
        }}
        disabled={!canMovePrevious() || !!loading?.save}
        startIcon={<ArrowBackIcon />}
      >
        {btnLabels[0]}
      </LoadingButton>
      {!isLastStep() ? (
        <LoadingButton
          variant="contained"
          onClick={async () => {
            await saveAndMoveToNext()
          }}
          loading={!!loading?.save}
          endIcon={<ArrowForwardIcon />}
        >
          <span>{btnLabels[1]}</span>
        </LoadingButton>
      ) : (
        <LoadingButton
          onClick={() => setSubmitWarning(true)}
          loading={!!loading?.save}
          endIcon={<ArrowForwardIcon />}
          variant="contained"
        >
          <span>{isMobile ? 'Submit' : 'Submit questionnaire'}</span>
        </LoadingButton>
      )}
    </Box>
  )
}

const demoButtons = [
  {
    icon: <DownloadIcon />,
    text: 'PDF',
    id: 'generate',
  },
  {
    icon: <LogoutIcon />,
    text: 'Logout',
    id: 'logout',
  },
]

export default Controls
