import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson, renderContent } from '../../utils/helpers'
import {
  PersonFieldPaths as paths,
  PersonDiagnosticTests,
} from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'
import ConditionalSection from '../../widgets/ConditionalSection'

export const FITResult = ({ ...props }) => {
  return <Chooser orderSchema={['normal', 'abnormal', 'noResult']} {...props} />
}

const FIT = ({ id, patient, config }) => {
  const { removePersonFITStatus, setPersonFITStatus } = useActions()
  const localization = useLocalization()
  const { isPerformed, type, result } =
    useSelector((state) => {
      const diagnosticTests = get(state, [...pathToPerson(id), 'diagnosticTests']) || []

      return diagnosticTests.find(({ type }) => type === PersonDiagnosticTests.FIT)
    }) || {}

  return (
    <ConditionalSection
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
      key="FIT"
      label={getLabel(localization, paths.FIT_STATUS_LABEL, patient)}
      conditionState={isPerformed}
      onConditionStateChange={(value) => {
        if (!type) {
          setPersonFITStatus({ id, type: 'type', value: PersonDiagnosticTests.FIT })
        }

        if (isPerformed !== value) {
          setPersonFITStatus({ id, type: 'isPerformed', value })
        }
      }}
      cleanUpChildQuestions={() => {
        removePersonFITStatus({ id, type: 'result' })
      }}
    >
      {renderContent({
        renderMap: {
          fitResult: (
            <FITResult
              key="fr"
              label={getLabel(localization, paths.DIAGNOSTIC_RESULT_LABEL, patient)}
              onChange={(value) => {
                if (result !== value) {
                  setPersonFITStatus({ id, type: 'result', value })
                }
              }}
              selectedValue={result}
              patient={patient}
              path={paths.DIAGNOSTIC_RESULT_LABEL}
            />
          ),
        },
        config,
      })}
    </ConditionalSection>
  )
}

export default FIT
