import { Container } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson, renderContent, subQuestions } from '../../utils/helpers'
import { PersonFieldPaths } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'
import DateOfBirth from '../../widgets/DateOfBirth'
import FullName from '../../widgets/FullName'
import HalfSiblingParent from '../../widgets/HalfSiblingParent'
import LifeStatus from '../../widgets/LifeStatus'
import SexAssignedAtBirth from '../../widgets/SexAssignedAtBirth'

/**
 * Selector for indicating twin of proband
 * @param {String} id - newly created Sibling id
 * @param {String} resolvePersonId - The reference person, ie proband, mother, father...
 * @returns {React.ReactElement} Twin property selector
 **/
const LinkTwins = ({ localization, id, resolvePersonId, isHalfSibling, twin, sex }) => {
  const { setTwinRelationship, setPersonPropertiesValue } = useActions()
  const probandId = useSelector((state) => get(state, ['questionnaire', 'probandId'])) || ''
  const probandSex = useSelector((state) => get(state, [...pathToPerson(probandId), 'sex'])) || ''
  const probandTwin = useSelector((state) => {
    const properties = get(state, [...pathToPerson(probandId), 'properties']) || []

    return properties.find(({ type }) => type === 'twin')
  })

  return resolvePersonId === probandId && probandTwin?.isPresent === 'Y' && !isHalfSibling ? (
    <Chooser
      label={getLabel(localization, PersonFieldPaths.IS_TWIN, 'person')}
      onChange={(value) => {
        setTwinRelationship({
          siblingId: id,
          probandId,
          relPath: value,
        })

        setPersonPropertiesValue({
          id,
          type: 'twin',
          value,
        })
      }}
      selectedValue={twin.value}
      orderSchema={['monozygoticTwin', 'dizygoticTwin', 'N']}
      path={PersonFieldPaths.IS_TWIN}
      disabled={[probandSex !== sex]}
    />
  ) : null
}

const SiblingQuestions = ({ personId, isHalfSibling, resolvePersonId, config }) => {
  const localization = useLocalization()
  const twin =
    useSelector((state) => {
      const properties = get(state, [...pathToPerson(personId), 'properties']) || []

      return properties.find(({ type }) => type === 'twin')
    }) || {}
  const sex = useSelector((state) => get(state, [...pathToPerson(personId), 'sex'])) || ''
  const { removePersonPropertiesList } = useActions()

  const renderMap = {
    sharedParent: isHalfSibling ? (
      <HalfSiblingParent key="sp" referencePersonId={personId} resolvePersonId={resolvePersonId} />
    ) : null,
    name: (
      <FullName
        key="fn"
        id={personId}
        localization={localization}
        config={subQuestions({ config, key: 'name' })}
      />
    ),
    sexAtBirth: (
      <SexAssignedAtBirth
        key="sab"
        id={personId}
        clearSexSpecificQuestions={removeTwinPropertyOnSexChange}
      />
    ),
    dateOfBirth: <DateOfBirth key="dob" id={personId} />,
    lifeStatus: (
      <LifeStatus
        key="ls"
        personId={personId}
        config={subQuestions({ config, key: 'lifeStatus' })}
      />
    ),
    twinType: (
      <LinkTwins
        key="twin"
        localization={localization}
        id={personId}
        resolvePersonId={resolvePersonId}
        isHalfSibling={isHalfSibling}
        twin={twin}
        sex={sex}
      />
    ),
  }
  const content = []

  renderContent({ renderMap, config, content })

  return <Container key={'sibling-' + personId}>{content}</Container>

  function removeTwinPropertyOnSexChange(value) {
    if (twin && sex && sex !== value) {
      removePersonPropertiesList({
        id: personId,
        type: 'twin',
      })
    }
  }
}

export default SiblingQuestions
