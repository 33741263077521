import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Card, CardContent, Collapse, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

import React, { useState } from 'react'

const CollapseIcon = styled((props) => {
  const { expand, ...other } = props

  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default function CollapsibleQuestion({ label, children }) {
  const [expanded, setExpanded] = useState(false)

  return (
    <Card sx={{ bgcolor: 'primary.light', borderRadius: 4 }} elevation={0}>
      <CardContent
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={handleExpandClick}
      >
        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
          {label}
        </Typography>
        <CollapseIcon expand={expanded} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </CollapseIcon>
      </CardContent>
      <Collapse in={expanded}>
        <CardContent sx={{ pt: 0 }}>{children}</CardContent>
      </Collapse>
    </Card>
  )

  function handleExpandClick() {
    setExpanded(!expanded)
  }
}
