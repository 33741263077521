import { Typography } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import { PersonFieldPaths } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'

const Twins = ({ id }) => {
  const localization = useLocalization()
  const twin =
    useSelector((state) => {
      const properties = get(state, [...pathToPerson(id), 'properties']) || []

      return properties.find(({ type }) => type === 'twin')
    }) || {}
  const sex = useSelector((state) => state.questionnaire.persons[id].sex)
  const { setPersonPropertiesIsPresent, removePersonPropertiesListFromAll } = useActions()
  const { isPresent = '' } = twin

  return (
    <Chooser
      orderSchema={['Y', 'N', 'U']}
      path="yesNoUnknown"
      key="Twins"
      label={getLabel(localization, PersonFieldPaths.IS_TWIN, 'patient')}
      onChange={async (value) => {
        if (value === 'U' || value === 'N') {
          removePersonPropertiesListFromAll({ type: 'twin' })
        }

        isPresent !== value && setPersonPropertiesIsPresent({ id, type: 'twin', value })
      }}
      selectedValue={isPresent}
      person={'patient'}
      disabled={[sex === 'U']}
    >
      <Typography variant="subtitle2">
        (If yes, please choose &ldquo;sex assigned at birth&rdquo; above)
      </Typography>
    </Chooser>
  )
}

export default Twins
