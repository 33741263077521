import { TextField } from '@mui/material'
import { debounce } from 'lodash'
import React, { useMemo, useState } from 'react'
import FormLabel from './FormLabel'

const TextFieldQuestion = ({ label, value, handleChange, fullWidth = false, multiline = true }) => {
  const [text, setText] = useState(value)
  const debounceAction = useMemo(() => debounce(handleChange, 200), [handleChange])

  return (
    <FormLabel label={label} fullWidth={fullWidth}>
      <TextField
        fullWidth={fullWidth}
        multiline={multiline}
        inputProps={{ maxLength: 65000 }}
        onChange={({ target: { value } }) => {
          setText(value)
          debounceAction(value)
        }}
        value={text}
      />
    </FormLabel>
  )
}

export default TextFieldQuestion
