import { Box, Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { renderContent } from '../../utils/helpers'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import FormLabel from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

function RelativeCardiacDevice({ personId, personLabel }) {
  const { setPersonConditions, removePersonConditions, setPersonConditionTreatments } = useActions()
  const { heartProblem = {}, config = [] } = useSelector(
    ({ questionnaire: { persons }, configuration }) => ({
      heartProblem: persons[personId].conditions?.find(({ id }) => id === 'heartProblems'),
      config: configuration.sectionConfig['heart-health-section'].subSectionConfig[
        'relatives-cardiac-device'
      ].questions.find(({ questionId }) => questionId === 'relatives-cardiac-device')?.subQuestions,
    }),
  )
  const { notes = '', ageAtTreatmentPerformed = '' } =
    heartProblem.treatments?.find(({ type }) => type === 'icd') || {}
  const isChecked = heartProblem.isPresent === 'Y' || false

  return (
    <Box>
      <FormControlLabel
        label={personLabel}
        control={<Checkbox checked={isChecked} onChange={handleCheckbox} />}
      />
      {isChecked && (
        <FollowUpQuestions>
          {renderContent({
            renderMap: {
              cardiacDeviceType: (
                <TextFieldQuestion
                  key="cdt"
                  label="What type of device was received?"
                  value={notes}
                  handleChange={(value) => {
                    setPersonConditionTreatments({
                      id: personId,
                      conditionId: 'heartProblems',
                      treatmentType: 'icd',
                      isPerformed: 'Y',
                      value: { notes: value },
                    })
                  }}
                />
              ),
              cardiacDeviceDate: (
                <FormLabel key="cdd" label="Approximate age when they received the device?">
                  <IntegerDetails
                    value={ageAtTreatmentPerformed}
                    onChange={(value) =>
                      setPersonConditionTreatments({
                        id: personId,
                        conditionId: 'heartProblems',
                        treatmentType: 'icd',
                        isPerformed: 'Y',
                        value: { ageAtTreatmentPerformed: value },
                      })
                    }
                  />
                </FormLabel>
              ),
            },
            config,
          })}
        </FollowUpQuestions>
      )}
    </Box>
  )

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleCheckbox({ target: { checked } }) {
    // on checkbox selected
    if (checked) {
      setPersonConditions({ id: personId, conditionId: 'heartProblems' })
      setPersonConditionTreatments({
        id: personId,
        conditionId: 'heartProblems',
        treatmentType: 'icd',
        isPerformed: 'Y',
      })

      return
    }
    // on checkbox deselected
    removePersonConditions({
      id: personId,
      conditionId: 'heartProblems',
    })
  }
}

export default RelativeCardiacDevice
