import { Box } from '@mui/material'
import React from 'react'

const CheckBoxList = ({ children, ...props }) => (
  <Box pl={2} display="flex" flexDirection="column" {...props}>
    {children}
  </Box>
)

export default CheckBoxList
