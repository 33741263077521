import { Container } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { renderContent } from '../../utils/helpers'
import ConditionalSection from '../../widgets/ConditionalSection'
import FormLabel from '../../widgets/FormLabel'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

export default function RelatedByBlood({ ancestryProperty, label, subLabel, config, probandId }) {
  const { setPartnershipProps } = useActions()

  const { isPresent = '', value = '' } =
    useSelector((state) => {
      const relationships = get(state, ['questionnaire', 'relationships']) || []
      let pIndex = -1
      const rIndex = relationships.findIndex(({ properties = [] }) => {
        const i = properties.findIndex(({ type }) => type === ancestryProperty)
        if (i > -1) {
          pIndex = i

          return true
        }

        return false
      })

      return rIndex > -1 ? get(relationships, [rIndex, 'properties', pIndex]) : null
    }) || {}

  const setRelationship = (_isPresent) => {
    if (isPresent !== _isPresent) {
      const relatedByBlood = {
        type: ancestryProperty,
        isPresent: _isPresent,
      }

      if (isPresent !== 'Y') {
        relatedByBlood.value = ''
      }

      setPartnershipProps({
        id: probandId,
        relationProperties: [relatedByBlood],
      })
    }
  }

  const setRelationshipDesc = (desc) => {
    const relationProperty = {
      type: ancestryProperty,
      isPresent,
      value: desc,
    }

    // It is a workaround solution.
    // It is better delete `value` property here
    // than in `FamilyHelper`.
    if (!desc) {
      delete relationProperty.value
    }

    setPartnershipProps({
      id: probandId,
      relationProperties: [relationProperty],
    })
  }

  return (
    <Container>
      <ConditionalSection
        label={label}
        showWhenCondition="Y"
        conditionState={isPresent}
        onConditionStateChange={(value) => setRelationship(value)}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={() => null}
      >
        {subLabel &&
          renderContent({
            renderMap: {
              parentsRelated: (
                <FormLabel label={subLabel} key="pr">
                  <TextFieldQuestion
                    fullWidth
                    inputProps={{ maxLength: 20000 }}
                    handleChange={(value) => setRelationshipDesc(value)}
                    value={value}
                  />
                </FormLabel>
              ),
            },
            config,
          })}
      </ConditionalSection>
    </Container>
  )
}
