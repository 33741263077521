import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import { PersonFieldPaths } from '../../utils/QuestionnaireStateManager'
import FormLabel from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'

const MenstrualPeriod = ({ id }) => {
  const localization = useLocalization()
  const ageAtMenarche =
    useSelector((state) => get(state, [...pathToPerson(id), 'cancerRiskData', 'ageAtMenarche'])) ||
    ''
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex'])) || ''
  const { setPersonAgeAtMenarche } = useActions()

  return sex === 'F' ? (
    <FormLabel label={getLabel(localization, PersonFieldPaths.AGE_AT_MENARCHE, 'patient')}>
      <IntegerDetails
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            max: 150,
          },
        }}
        value={ageAtMenarche}
        onChange={(value) => {
          if (value !== ageAtMenarche) {
            setPersonAgeAtMenarche({ id, value })
          }
        }}
      />
    </FormLabel>
  ) : null
}

export default MenstrualPeriod
