import { Alert } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson, renderContent } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import FormLabel from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'

const HRTType = ({ label, patient, setPersonHrtType, id }) => {
  const hrtType =
    useSelector((state) => get(state, [...pathToPerson(id), 'cancerRiskData', 'hrtType'])) || ''

  return (
    <Chooser
      orderSchema={['estrogen', 'combined', 'unknown']}
      path={paths.HRT_TYPE_LABEL}
      label={label}
      onChange={(value) => {
        if (value !== hrtType) {
          setPersonHrtType({ id, value })
        }
      }}
      selectedValue={hrtType}
      patient={patient}
    />
  )
}
const HRTYears = ({ onChange, id }) => {
  const hrtLengthOfUse =
    useSelector((state) => get(state, [...pathToPerson(id), 'cancerRiskData', 'hrtLengthOfUse'])) ||
    ''

  return (
    <FormLabel label="For how many years total?">
      <IntegerDetails
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            max: 150,
          },
        }}
        onChange={onChange}
        value={hrtLengthOfUse}
      />
    </FormLabel>
  )
}
const HRTCalculationInfo = () => {
  return (
    <Alert key="info" severity="info">
      Please provide the total number of years you have used HRT, even if your usage was not
      consecutive. If you used HRT for less than a year, enter &apos;1&apos;. If you used HRT for
      only a portion of a year, please round up to the nearest whole year.
    </Alert>
  )
}

const HRT = ({ id, patient, config }) => {
  const localization = useLocalization()
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex'])) || ''
  const hrtUsage =
    useSelector((state) => get(state, [...pathToPerson(id), 'cancerRiskData', 'hrtUsage'])) || ''
  const {
    setPersonHrtUsage,
    setPersonHrtType,
    setPersonHrtLengthOfUse,
    removePersonExtendedCancerRisk,
  } = useActions()

  if (sex !== 'F') return null

  return (
    <>
      <Chooser
        label={getLabel(localization, paths.HRT_USAGE, patient)}
        onChange={(value) => {
          if (value !== hrtUsage) {
            setPersonHrtUsage({ id, value })
            if (value === 'N' || value === 'unknown') {
              setPersonHrtType({ id, value: '' })
              setPersonHrtLengthOfUse({ id, value: '' })
              removePersonExtendedCancerRisk({ id, type: 'hrtYears' })
            }
          }
        }}
        orderSchema={['using', 'stoppedWithin5', 'stoppedMore5', 'N', 'unknown']}
        selectedValue={hrtUsage}
        patient={patient}
        path={paths.HRT_USAGE}
      />
      {hrtUsage === 'using' || hrtUsage === 'stoppedWithin5' || hrtUsage === 'stoppedMore5' ? (
        <FollowUpQuestions>
          {renderContent({
            renderMap: {
              hrtType: (
                <HRTType
                  key="ht"
                  label={getLabel(localization, paths.HRT_TYPE_LABEL, patient)}
                  setPersonHrtType={setPersonHrtType}
                  patient={patient}
                  id={id}
                />
              ),
              hrtYears: (
                <HRTYears
                  key="hy"
                  onChange={(value) => {
                    setPersonHrtLengthOfUse({ id, value })
                  }}
                  id={id}
                />
              ),
              hrtCalculation: <HRTCalculationInfo key="hci" />,
            },
            config,
          })}
        </FollowUpQuestions>
      ) : null}
    </>
  )
}

export default HRT
