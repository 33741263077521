import React from 'react'
import { useSelector } from 'react-redux'
import { getLabel, getOptions } from '../utils/helpers'
import ButtonGroup from './ButtonGroup'
import FormLabel from './FormLabel'

function Chooser({
  label,
  onChange,
  disabled,
  selectedValue,
  orderSchema,
  path,
  patient,
  children,
  options = undefined,
  fullWidth = false,
}) {
  const localization = useSelector(({ localization }) => localization)

  return (
    <FormLabel label={label || getLabel(localization, path)} fullWidth={fullWidth}>
      {children}
      <ButtonGroup
        disabled={disabled}
        onChange={onChange}
        selectedValue={selectedValue}
        options={options ?? getOptions(localization, path, orderSchema, patient)}
      />
    </FormLabel>
  )
}

export default Chooser
