import { useSelector } from 'react-redux'

/**
 * Prevents unnecessary re-renders when nested properties change
 * CAUTION ONLY use for lists such as Persons, Relationships, etc.
 */
export function useShallowSelector(selector) {
  return useSelector(selector, (objA, objB) => {
    if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
      return false
    }
    const keysA = Object.keys(objA)
    const keysB = Object.keys(objB)
    if (keysA.length !== keysB.length) return false

    for (let i = 0; i < keysA.length; i++) {
      if (!Object.prototype.hasOwnProperty.call(objB, keysA[i])) {
        return false
      }
    }

    return true
  })
}

export function useLocalization() {
  return useSelector((state) => state.localization)
}
