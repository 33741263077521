import CloseIcon from '@mui/icons-material/Close'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import React, { useContext, useState, useRef } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useActions } from '../hooks/useActions.jsx'
import { useLocalization } from '../redux/reduxHooks.js'
import { theme } from '../theme'
import Context from '../utils/context/Context'
import { getLabel } from '../utils/helpers'
import { EventType, NavigationPaths } from '../utils/QuestionnaireStateManager.js'
import AppVersion from '../widgets/AppVersion'
import CollapsibleError from '../widgets/CollapsibleError'
import DialogWindow from '../widgets/DialogWindow'
import CurrentStep from './CurrentStep'
import { SessionContext } from './SessionTimeout'

function DrawerItem({ nodeItem, saveAndGoToStep }) {
  const { isSelected, isExpanded, label, type } = nodeItem
  const isFolder = type === 'folder'
  const { loading } = useContext(Context)
  const expandIcon = () => {
    if (!isFolder) return null

    return isExpanded ? <ExpandLess /> : <ExpandMore />
  }

  return (
    <ListItemButton
      disabled={!!loading?.save}
      selected={isSelected}
      onClick={async () => {
        const _node = { ...nodeItem }
        _node.eventType = EventType.PAGE_JUMP
        await saveAndGoToStep(_node)
      }}
    >
      <ListItemText primary={label} />
      {expandIcon()}
    </ListItemButton>
  )
}

function Navigation({ children }) {
  const {
    dialogBox,
    dialogBox: { errorMessage = '', show, type },
    setDialogBox,
    loading,
    setLoading,
    setAuthState,
    addToast,
  } = useContext(Context)
  const { activate } = useContext(SessionContext)
  const scrollBody = useRef(null)
  const [selectedNavId, setSelectedNavId] = useState('introduction')
  const [expandedNodeId, setExpandedNodeId] = useState(null)
  const [burgerMenuOpened, setBurgerMenuOpened] = useState(false)

  const localization = useLocalization()
  const store = useStore()
  const probandId = useSelector((state) => state.questionnaire.probandId)
  const { sendStateData, submitStateData, sendMetricsData } = useActions()

  return (
    <Box
      sx={{
        display: 'flex',
        margin: '0',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'none',
          padding: '10px',
          position: 'fixed',
          top: '0',
          right: '0',
          zIndex: '10',
          [theme.breakpoints.down('md')]: {
            display: 'initial',
          },
        }}
      >
        <IconButton color="primary" onClick={() => setBurgerMenuOpened(!burgerMenuOpened)}>
          {burgerMenuOpened ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </Box>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          [theme.breakpoints.down('md')]: {
            ...(burgerMenuOpened
              ? {
                  position: 'fixed',
                  top: '0',
                  left: '0',
                  height: '100vh',
                  width: '100vw',
                  maxWidth: '100vw',
                  background: theme.palette.white.main,
                  padding: '0',
                  zIndex: '2',
                }
              : {
                  display: 'none',
                }),
          },
          '& .MuiDrawer-paper': {
            paddingTop: {
              md: '24px',
              xs: '48px',
            },
          },
        }}
      >
        <List>
          {getStepsAndFolders()
            .map((step) => renderSidebarNavItem(step))
            .map((navItem) => {
              const { isExpanded, type, key } = navItem

              return type === 'folder' ? (
                <Box key={key}>
                  <DrawerItem
                    nodeItem={{ ...navItem }}
                    saveAndGoToStep={(node) => saveAndGoToStep(node)}
                  />
                  <Collapse
                    in={isExpanded}
                    sx={{
                      '& .MuiListItemButton-root': {
                        paddingLeft: '35px',
                      },
                      '& .MuiCollapse-wrapper': {
                        margin: '-10px 0',
                      },
                    }}
                  >
                    {navItem.childNodes.map((childNode) => (
                      <DrawerItem
                        key={childNode.key}
                        nodeItem={childNode}
                        saveAndGoToStep={(node) => saveAndGoToStep(node)}
                      />
                    ))}
                  </Collapse>
                </Box>
              ) : (
                <DrawerItem
                  key={navItem.key}
                  nodeItem={navItem}
                  saveAndGoToStep={(node) => saveAndGoToStep(node)}
                />
              )
            })}
        </List>
        <Box flexGrow={1}></Box>
        <AppVersion />
      </Drawer>
      <CurrentStep
        scrollRef={scrollBody}
        loadingControls={loadingControls}
        icon={_.get(getCurStep(), 'props.icon')}
        navTitle={_.get(getCurStep(), 'props.navTitle')}
        currentStep={getCurStep()}
        handleMovePrevious={handleMovePrevious}
        canMovePrevious={canMovePrevious}
        isLastStep={isLastStep}
        saveAndMoveToNext={saveAndMoveToNext}
        setSubmitWarning={() => setDialogBox({ show: true, type: 'submitWarning' })}
        btnLabels={[
          getLabel(localization, NavigationPaths.BACK, 'navigation'),
          getLabel(localization, NavigationPaths.NEXT, 'navigation'),
        ]}
      />
      <DialogWindow
        open={show && type === 'submitWarning'}
        onClose={() => setDialogBox({ show: false })}
        handleConfirm={async () => submitQuestionnaire()}
        title="Are you ready to submit the questionnaire?"
        text={
          'Your responses will be final and you will not be able to undo this action. \
            You will be able to discuss or edit information \
            with your genetics provider at your appointment.'
        }
      />
      <DialogWindow
        open={show && type === 'showSaveError'}
        onClose={() => nextAction()}
        handleConfirm={async () => saveAndMoveToNext()}
        title="Save Error"
        color="error"
        text={
          'Please check your internet connection or \
            try again later to ensure that your responses are captured correctly.'
        }
        primaryBtn="Try Again"
        secondaryBtn="Continue without saving"
      >
        {errorMessage && Array.isArray(errorMessage)
          ? errorMessage.map((error, index) => (
              <CollapsibleError
                key={error.title + index.toString()}
                title={`${index + 1}: ${error.title}`}
              >
                {error.detail}
              </CollapsibleError>
            ))
          : null}
      </DialogWindow>
      <DialogWindow
        open={show && type === 'submitFailed'}
        onClose={() => setDialogBox({ show: false })}
        handleConfirm={async () => submitQuestionnaire()}
        title="Submission Error"
        color="error"
        text={
          'Please check your internet connection or \
            try again later to ensure that your responses are captured correctly.'
        }
        primaryBtn="Try Again"
      >
        {errorMessage ? <Typography variant="body2">{`\n${errorMessage}`}</Typography> : null}
      </DialogWindow>
      <DialogWindow
        open={show && type === 'missingFields'}
        onClose={() => setDialogBox({ show: false })}
        handleConfirm={async () => {
          await saveAndGoToStep({
            id: 'person-proband',
            label: 'Your Information',
            eventType: EventType.GO_BACK,
          })
          setDialogBox({ show: false })
          loadingControls('dialog', false)
        }}
        title="Missing required fields"
        color="error"
        text={errorMessage}
        primaryBtn="Go to missing fields"
      />
    </Box>
  )

  function scrollTop() {
    scrollBody.current.scrollTop = 0
  }

  function canMovePrevious() {
    return getCurStepIndex(getCurStep()) > 0
  }

  function isLastStep() {
    return getCurStepIndex(getCurStep()) === getSteps().length - 1
  }

  function handleMovePrevious() {
    scrollTop()

    return moveSteps(-1, getCurStepIndex(getCurStep()), EventType.PREVIOUS_STEP)
  }

  function handleMoveNext() {
    scrollTop()

    return moveSteps(1, getCurStepIndex(getCurStep()), EventType.SAVE_AND_CONTINUE)
  }

  function loadingControls(type, value) {
    setLoading((prevState) => ({
      ...prevState,
      [type]: value,
    }))
  }

  async function saveAndMoveToNext() {
    loadingControls('save', true)

    await sendStateData()
    await handleMoveNext()
    activate()
    scrollTop()
  }

  async function saveAndGoToStep(node) {
    if (node.type === 'folder') {
      if (node.id === expandedNodeId) {
        setExpandedNodeId(false)
      } else {
        setExpandedNodeId(node.id)
      }
    } else if (node.id !== selectedNavId) {
      loadingControls('save', true)

      await sendStateData()
      await setCurStep(node)
      activate()
    }
  }

  function nextAction() {
    dialogBox.action(dialogBox.node)
    setDialogBox({ show: false })
    scrollTop()
  }

  // check if all required fields are completed
  function missingRequiredFields() {
    const { name, sex } = store.getState().questionnaire.persons[probandId]
    const missingFields = []

    // Full name
    if (_.isEmpty(name?.firstName)) missingFields.push('First Name')
    if (_.isEmpty(name?.lastName)) missingFields.push('Last Name')
    // Sex field
    if (_.isEmpty(sex) || sex === 'U') missingFields.push('Sex assigned at birth')

    return _.isEmpty(missingFields) ? null : missingFields
  }

  async function submitQuestionnaire() {
    const fields = missingRequiredFields()

    if (fields) {
      loadingControls('dialog', false)
      setDialogBox({
        show: true,
        type: 'missingFields',
        errorMessage: 'You must fill out all required fields: ' + fields.join(', '),
      })

      return
    }

    loadingControls('submit', true)

    await sendStateData()

    // Must use `thunk` return, not from `fetch` return.
    const {
      meta: { requestStatus = '' },
    } =
      (await submitStateData({
        sendMetricsData: () =>
          sendMetricsData({
            newDestinationValue: 'ThankYou',
            eventType: EventType.SUBMIT,
          }),
      })) || {}

    if (requestStatus !== 'fufilled') {
      setDialogBox({
        show: true,
        type: 'submitFailed',
        errorMessage: `Error: The application encountered an unexpected error`,
      })
    } else {
      setDialogBox(() => ({ show: false }))

      setAuthState(() => ({
        isAuthenticated: false,
        loggingIn: true,
      }))

      addToast('success', 'info', 'Successfully Submitted')
    }
    loadingControls('dialog', false)
  }

  function renderSidebarNavItem(step) {
    if (Array.isArray(step)) {
      const childNodes = step.map((childNode, i) => {
        if (i !== 0) {
          return {
            key: childNode.key,
            id: childNode.props.navId,
            label: childNode.props.navTitle,
            disabled: !!loading?.save,
            isSelected: childNode === getCurStep(),
          }
        } else {
          return childNode
        }
      })

      const folderInfo = {
        key: childNodes[0].id,
        id: childNodes[0].id,
        label: childNodes[0].label,
        disabled: !!loading?.save,
        isSelected: false,
        isExpanded: childNodes[0].id === getExpandedFolder(),
        type: 'folder',
      }

      // set the first item in the array as the folder step
      childNodes.forEach((childNode) => {
        childNode.containingFolder = childNodes[0].id
      })

      // remove folder step from child node list
      childNodes.shift()
      folderInfo.childNodes = childNodes

      return folderInfo
    }

    return {
      key: step.key,
      id: step.props.navId,
      label: step.props.navTitle,
      disabled: !!loading?.save,
      isSelected: step === getCurStep(),
    }
  }

  function getExpandedFolder() {
    if (expandedNodeId) {
      const steps = _.flattenDeep(getStepsAndFolders()).filter((step) => {
        return step.type === 'folder' && step.id === expandedNodeId
      })

      return steps[0].id
    } else {
      return false
    }
  }

  function getStepsAndFolders() {
    return _.flatten(children).filter((step) => step && step.key !== 'controls')
  }

  function getSteps() {
    return _.flattenDeep(children).filter(
      (step) => step && step.type !== 'folder' && step.key !== 'controls',
    )
  }

  /**
   * Uses selectedNavId to return the currently selected step component instance
   * from children.
   *
   * selectedNavId is expected to hold a string corresponding
   * to the navId value of the instance.
   */
  function getCurStep() {
    if (selectedNavId) {
      const steps = _.flattenDeep(getSteps()).filter((step) => {
        return step.type !== 'folder' && step.props[Navigation.NAV_ID_PROPNAME] === selectedNavId
      })

      return steps[0]
    } else {
      return getSteps()[0]
    }
  }

  /**
   * Gets the index of the current step by looking through all steps.
   */
  function getCurStepIndex(curStep) {
    return _.findIndex(getSteps(), curStep)
  }

  /**
   * Moves the current navigation step by the requested number of steps.
   *
   * @param {number} numberSteps a negative
   * or positive integer representing the number of steps to move.
   */
  async function moveSteps(numberSteps, currentStepIndex, eventType) {
    const newStepIndex = currentStepIndex + numberSteps

    if (newStepIndex < 0 || newStepIndex >= getSteps().length) {
      throw new Error('New step is out of bounds')
    }

    const folderId = getContainingFolder(newStepIndex) || expandedNodeId

    setSelectedNavId(getSteps()[newStepIndex].props[Navigation.NAV_ID_PROPNAME])
    setExpandedNodeId(folderId)

    await updateMetricPages(eventType, '', newStepIndex)
  }

  async function updateMetricPages(eventType, pageLabel = '', newStepIndex = undefined) {
    await sendMetricsData({
      newDestinationValue: pageLabel || getSteps()[newStepIndex].props?.navTitle,
      eventType,
    })
  }

  /**
   * Sets the current step based on navigation ID of the given node.
   *
   * @param {string} node The node to grab the navId from
   */
  async function setCurStep(node) {
    if (node.type !== 'folder') {
      setSelectedNavId(node.id)
      setBurgerMenuOpened(false)
      scrollTop()
    }

    if (node.label && node.eventType) {
      await updateMetricPages(node.eventType, node.label, undefined).catch(() => {})
    }
  }

  function getContainingFolder(stepIndex) {
    const compareStep = (step, currentStep) => {
      if (Array.isArray(step)) {
        return step.map((step) => compareStep(step, currentStep))
      } else {
        return step === currentStep
      }
    }

    if (getStepsAndFolders().indexOf(getSteps()[stepIndex]) === -1) {
      let index = null

      const folders = getStepsAndFolders()
        .filter((step) => Array.isArray(step))
        .map((step) => step.map((childNode) => childNode))

      folders
        .map((step) => compareStep(step, getSteps()[stepIndex]))
        .forEach((isContainingStep, i) => {
          if (isContainingStep.includes(true)) {
            index = i
          }
        })

      return folders[index][0].id
    } else {
      return false
    }
  }
}

Navigation.NAV_ID_PROPNAME = 'navId'

export default Navigation
