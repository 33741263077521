import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import HeartConditionsList from '../HeartHealthHistory/HeartConditionList'
import HeartConditionsModal from '../HeartHealthHistory/HeartConditionsModal'

function RelativeHeartConditions({ personId, personLabel }) {
  const { setPersonConditions, removePersonConditions, removePersonHeartConditions } = useActions()
  const {
    isPresent,
    heartConditions,
    config = [],
  } = useSelector(({ questionnaire: { persons }, configuration }) => ({
    heartConditions: persons[personId].conditions?.filter(({ type }) => type === 'heartConditions'),
    isPresent:
      persons[personId].conditions?.find(({ id }) => id === 'heartConditions')?.isPresent || '',
    config: configuration.sectionConfig['heart-health-section'].subSectionConfig[
      'relatives-heart-conditions'
    ].questions.find(({ questionId }) => questionId === 'relative-heart-condition').subQuestions,
  }))
  const [modalOpened, setModalOpened] = React.useState(false)
  const isChecked = isPresent === 'Y' || false

  return (
    <Box>
      <FormControlLabel
        label={personLabel}
        control={<Checkbox checked={isChecked} onChange={handleCheckbox} />}
      />
      {isChecked && (
        <FollowUpQuestions>
          <Button startIcon={<AddIcon />} onClick={() => setModalOpened(true)}>
            Add Heart Condition
          </Button>
          <HeartConditionsModal
            open={modalOpened}
            setOpen={setModalOpened}
            personId={personId}
            config={config}
          />
          <HeartConditionsList id={personId} heartConditions={heartConditions} />
        </FollowUpQuestions>
      )}
    </Box>
  )

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleCheckbox({ target: { checked } }) {
    // on checkbox selected
    if (checked) {
      setPersonConditions({ id: personId, conditionId: 'heartConditions' })

      return
    }
    // on checkbox deselected
    removePersonConditions({
      id: personId,
      conditionId: 'heartConditions',
    })
    removePersonHeartConditions({ id: personId })
  }
}

export default RelativeHeartConditions
