import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Card, CardContent, CardHeader, Collapse, IconButton } from '@mui/material'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import { getFullName, pathToPerson } from '../utils/helpers'

const FullName = ({ id }) => {
  const { firstName, lastName } =
    useSelector((state) => get(state, [...pathToPerson(id), 'name'])) || {}

  return <>{getFullName(firstName, lastName)}</>
}

const Relationship = ({ id }) => {
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex'])) || ''
  const relationshipToProband =
    useSelector((state) => get(state, [...pathToPerson(id), 'relationshipToProband'])) || ''
  const person = () => {
    if (relationshipToProband.includes('Maternal')) {
      return `Mother's`
    } else if (relationshipToProband.includes('Paternal')) {
      return `Father's`
    } else {
      return `Patient's`
    }
  }
  const relationship = () => {
    const trimmedRelationship = relationshipToProband
      .replace('probands', '')
      .replace('Maternal', '')
      .replace('Paternal', '')
      .replace('Half', '')
      .trim()
    const isSibling = !!trimmedRelationship.includes('Sibling')

    if (sex === 'F') {
      return isSibling ? 'Sister' : 'Daughter'
    } else if (sex === 'M') {
      return isSibling ? 'Brother' : 'Son'
    }

    return trimmedRelationship
  }
  const isHalfSibling = relationshipToProband.includes('HalfSibling')

  return <>{person() + ' ' + (isHalfSibling ? 'Half-' : '') + relationship()}</>
}

export default function InternalCard({ id, removePersonEntry, children }) {
  const [entryRendererOpen, toggleEntryRenderer] = useState(true)
  const stateSnapshot = useStore().getState()

  useEffect(() => {
    const person = get(stateSnapshot, ['questionnaire', 'persons', id], {})
    const { name = {}, sex = 'U', lifeStatus = {}, dateOfBirth = '', properties = [] } = person
    const _properties = properties.find(({ type }) => type === 'sharedParent' || type === 'twin')
    // If any info of the person is filled we collapse the card
    if (
      !!Object.keys(name).length ||
      !!Object.keys(lifeStatus).length ||
      !!dateOfBirth ||
      sex !== 'U' ||
      !!_properties
    ) {
      toggleEntryRenderer(false)
    }
  }, [])

  return (
    <Card elevation={2} sx={{ borderRadius: 2 }}>
      <CardHeader
        sx={{ cursor: 'pointer' }}
        avatar={<AccountCircleIcon fontSize="large" color="primary" />}
        title={<FullName id={id} />}
        subheader={<Relationship id={id} />}
        action={
          <>
            {entryRendererOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            <IconButton
              color="error"
              onClick={(e) => {
                e.stopPropagation()
                removePersonEntry()
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </>
        }
        onClick={() => toggleEntryRenderer(!entryRendererOpen)}
      />
      <Collapse in={entryRendererOpen}>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  )
}
