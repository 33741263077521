import { Container, Typography } from '@mui/material'
import React from 'react'

const FormLabel = ({ label, fullWidth = false, children, ...props }) => {
  return (
    <Container {...props} sx={{ gap: 1, p: 0 }}>
      <Typography variant="subtitle1" maxWidth={fullWidth ? '100%' : 600}>
        {label}
      </Typography>
      {children}
    </Container>
  )
}

export default FormLabel
