import { Autocomplete, Checkbox, TextField } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import React from 'react'
import { charRestriction } from '../utils/helpers'
import FormLabel from './FormLabel'

const filter = createFilterOptions()

/**
 * Dropdown selector with search and autocomplete for options
 * @param value single object if multiple is false
 * @param options format of { value: '...', label: '...' } or the equivalency check will fail
 */
const SearchSelect = ({
  label,
  value,
  options,
  onChange,
  inputLabel,
  filterOptions,
  multiple = false,
}) => {
  return (
    <FormLabel label={label}>
      <Autocomplete
        sx={{ pt: 1 }}
        disableCloseOnSelect
        freeSolo
        clearOnBlur
        multiple={multiple}
        blurOnSelect={!multiple}
        value={value}
        isOptionEqualToValue={({ value }, { value: _value }) => value === _value}
        options={options}
        getOptionLabel={(option) => option.label || ''}
        renderOption={(props, { value, label }, { selected }) => (
          <li key={value} {...props}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={inputLabel || 'Search...'}
            onChangeCapture={(e) => {
              e.target.value = charRestriction(e.target.value)
            }}
            onKeyDown={(e) => {
              // Prevents a crash where an undefined object is added to the list
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
              }
            }}
          />
        )}
        onChange={onChange}
        filterOptions={filterOptions || defaultFilteredOptions}
      />
    </FormLabel>
  )

  function defaultFilteredOptions(options, params) {
    const filtered = filter(options, params)

    const { inputValue } = params
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.value)
    if (inputValue !== '' && !isExisting) {
      filtered.push({ value: inputValue, label: `Add "${inputValue}"` })
    }

    return filtered
  }
}

export default SearchSelect
