import { Box, Container, FormControlLabel, OutlinedInput, Switch, Typography } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { useLocalization } from '../redux/reduxHooks'
import { getLabel, pathToPerson } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager'
import FollowUpQuestions from './FollowUpQuestions'
import FormLabel from './FormLabel'

const { HEIGHT, WEIGHT } = PersonFieldPaths
const FOOT_TO_INCHES = 12
const FOOT_TO_CM = 30.48
const KILOGRAM_TO_POUNDS = 2.205

const convertHeightFromMetricToImperial = (value) => {
  let result = value / FOOT_TO_CM
  if (result % 1 >= 0.9583) {
    result = Math.round(result)
  }
  const ft = Math.trunc(result)
  const inches = Math.round((result - ft) * FOOT_TO_INCHES)

  return { ft, in: inches }
}

const convertHeightFromImperialToMetric = (foot, inches) => {
  const _foot = Number.isNaN(foot) ? 0 : foot
  const _inches = Number.isNaN(inches) ? 0 : inches

  return _foot * FOOT_TO_CM + (_inches / FOOT_TO_INCHES) * FOOT_TO_CM
}

const convertWeightFromMetricToImperial = (value) => {
  return value * KILOGRAM_TO_POUNDS
}

const convertWeightFromImperialToMetric = (value) => {
  return value / KILOGRAM_TO_POUNDS
}

const getPersonHeightValueFromMetric = (height) => {
  return {
    cm: height,
    ...convertHeightFromMetricToImperial(height),
  }
}

const getPersonHeightValueFromImperial = (foot, inches) => {
  return {
    cm: convertHeightFromImperialToMetric(foot, inches),
    ft: foot,
    in: inches,
  }
}

const getPersonWeightValueFromMetric = (weight) => {
  return {
    kg: weight,
    lb: convertWeightFromMetricToImperial(weight),
  }
}

const getPersonWeightValueFromImperial = (weight) => {
  return {
    kg: convertWeightFromImperialToMetric(weight),
    lb: weight,
  }
}

const NumberInput = ({ ...props }) => (
  <OutlinedInput sx={{ maxWidth: '80px' }} type="tel" {...props} />
)

const InputContainer = ({ children, ...props }) => (
  <Box display="flex" alignItems="center" gap={1} {...props}>
    {children}
  </Box>
)

const MeasurementsSection = ({ id }) => {
  const { setPersonHeight, setPersonWeight, setIsMetricMeasurementSystem } = useActions()
  const localization = useLocalization()

  const isMetricMeasurementSystem = useSelector(
    (state) => state.questionnaire.isMetricMeasurementSystem,
  )
  const height = useSelector((state) =>
    get(state, [...pathToPerson(id), 'cancerRiskData', 'height']),
  )
  const weight = useSelector((state) =>
    get(state, [...pathToPerson(id), 'cancerRiskData', 'weight']),
  )
  const heightLabel = getLabel(localization, HEIGHT, 'patient')
  const weightLabel = getLabel(localization, WEIGHT, 'patient')

  const onChangeMetricHeightValue = (val) => {
    const { cm } = getPersonHeightValueFromMetric(parseFloat(val))
    setPersonHeight({ id, value: cm === 0 || Number.isNaN(cm) ? undefined : cm })
  }

  const onChangeImperialHeightValue = (foot, inches) => {
    const { cm } = getPersonHeightValueFromImperial(parseFloat(foot), parseFloat(inches))
    setPersonHeight({ id, value: cm === 0 ? undefined : cm })
  }
  const onChangeMetricWeightValue = (val) => {
    const { kg } = getPersonWeightValueFromMetric(parseFloat(val))
    setPersonWeight({ id, value: kg === 0 || Number.isNaN(kg) ? undefined : kg })
  }
  const onChangeImperialWeightValue = (val) => {
    const { kg } = getPersonWeightValueFromImperial(parseFloat(val))
    setPersonWeight({ id, value: kg === 0 || Number.isNaN(kg) ? undefined : kg })
  }

  return (
    <Container sx={{ gap: 1 }}>
      <InputContainer>
        <Typography variant="subtitle1">Measurements</Typography>
        <Box flexGrow={1}></Box>
        <FormControlLabel
          label="Metric"
          control={
            <Switch
              checked={isMetricMeasurementSystem}
              onChange={() => setIsMetricMeasurementSystem()}
            />
          }
        />
      </InputContainer>
      <FollowUpQuestions>
        <FormLabel label={heightLabel}>
          <InputContainer>
            {isMetricMeasurementSystem ? (
              <>
                <NumberInput
                  value={Math.round(getPersonHeightValueFromMetric(height).cm) || ''}
                  onChange={(e) => onChangeMetricHeightValue(e.target.value)}
                />
                <Typography>cm</Typography>
              </>
            ) : (
              <>
                <NumberInput
                  value={Math.round(getPersonHeightValueFromMetric(height).ft) || ''}
                  onChange={(e) =>
                    onChangeImperialHeightValue(
                      e.target.value,
                      getPersonHeightValueFromMetric(height).in,
                    )
                  }
                />
                <Typography>feet</Typography>
                <NumberInput
                  value={Math.round(getPersonHeightValueFromMetric(height).in) || ''}
                  onChange={(e) =>
                    onChangeImperialHeightValue(
                      getPersonHeightValueFromMetric(height).ft,
                      e.target.value,
                    )
                  }
                />
                <Typography>inches</Typography>
              </>
            )}
          </InputContainer>
        </FormLabel>
        <FormLabel label={weightLabel}>
          <InputContainer>
            {isMetricMeasurementSystem ? (
              <>
                <NumberInput
                  value={Math.round(getPersonWeightValueFromMetric(weight).kg) || ''}
                  onChange={(e) => onChangeMetricWeightValue(e.target.value)}
                />
                <Typography>Kg</Typography>
              </>
            ) : (
              <>
                <NumberInput
                  value={Math.round(getPersonWeightValueFromMetric(weight).lb) || ''}
                  onChange={(e) => onChangeImperialWeightValue(e.target.value)}
                />
                <Typography>lbs</Typography>
              </>
            )}
          </InputContainer>
        </FormLabel>
      </FollowUpQuestions>
    </Container>
  )
}
export default MeasurementsSection
