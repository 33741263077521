import { Box, Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel } from '../../utils/helpers'
import {
  PersonProperties as properties,
  PersonFieldPaths as paths,
} from '../../utils/QuestionnaireStateManager'
import CancerHistory from '../../widgets/CancerHistory'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'

export default function DiagnosedCancer({ personId, personLabel, lastItemUnchecked }) {
  const { setPersonPropertiesIsPresent, removePersonPropertiesList, setPersonCancers } =
    useActions()
  const { localization, probandId, proband, relative, config } = useSelector(
    ({ questionnaire, localization, configuration }) => ({
      localization,
      probandId: questionnaire.probandId,
      proband: questionnaire.persons[questionnaire.probandId],
      relative: questionnaire.persons[personId],
      config:
        configuration.sectionConfig['cancer-related-question'].subSectionConfig[
          'diagnosed-with-cancer'
        ].questions,
    }),
  )
  const isChecked = !!relative.properties.find((i) => i.type === properties.HAS_CANCER)

  return (
    <Box>
      <FormControlLabel
        label={personLabel}
        control={<Checkbox checked={isChecked} onChange={handleCheckbox} />}
      />
      {isChecked && (
        <FollowUpQuestions>
          <CancerHistory
            label={getLabel(localization, paths.TYPES_OF_CANCER, 'person')}
            personId={personId}
            config={config}
          />
        </FollowUpQuestions>
      )}
    </Box>
  )

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleCheckbox(e) {
    const { properties: probandProperties = [] } = proband
    const hasProbandProperty =
      probandProperties.find(({ type }) => type === properties.HAS_RELATIVE_HAS_CANCER)
        ?.isPresent !== 'Y'

    // on checkbox selected
    if (e.target.checked) {
      if (hasProbandProperty) {
        setPersonPropertiesIsPresent({
          id: probandId,
          type: properties.HAS_RELATIVE_HAS_CANCER,
          value: 'Y',
        })
      }
      setPersonPropertiesIsPresent({
        id: personId,
        type: properties.HAS_CANCER,
        value: 'Y',
      })

      return
    }

    // on checkbox deselected
    if (lastItemUnchecked) {
      setPersonPropertiesIsPresent({
        id: probandId,
        type: properties.HAS_RELATIVE_HAS_CANCER,
        value: 'N',
      })
    }
    removePersonPropertiesList({
      id: personId,
      type: properties.HAS_CANCER,
    })
    if (relative.cancers?.length > 0) {
      setPersonCancers({
        id: personId,
        value: [],
      })
    }
  }
}
